import React from "react";
import Helmet from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";

import Layout from "../components/Layout";


class AppFeedbackTemplate extends React.Component {
  render() {
    return (
      <div className="app-feedback-wrapper flex-row justc-center alitems-center">
        <div className="app-feedback-card flex-row justc-space-b alitems-stretch
                        s-flex-column">
          <div className="card-section left padding-horizontal-l padding-vertical-l flex-column justc-center alitems-center
                          m-padding-horizontal-m m-padding-vertical-m s-padding-horizontal-s s-padding-vertical-s">
            <div class="feedback-logo mgb-m s-mgt-s s-mgb-s">
              <img src="/img/logo-electre-web-white.svg" alt="logo" />
            </div>
            <div class="feedback-illu mgb-m">
              <img src="/img/messages-illu.svg" alt="logo" />
            </div>
            <div class="feedback-text s-mgb-s">
              Ce formulaire est dédié aux questions et suggestions concernant la nouvelle version de l’application Electre.com
            </div>
          </div>
          <div className="card-section right padding-horizontal-l padding-vertical-l
                          m-padding-horizontal-m m-padding-vertical-m s-padding-horizontal-s s-padding-vertical-s">
            <form className="flex-column justc-start alitems-center"
                name="electre-app-feedback" 
                method="post" 
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                data-netlify-recaptcha="true"
                action="/contact-confirmation-app-feedback/">
              <input type="hidden" name="form-name" value="electre-app-feedback" />
              <input type="hidden" name="bot-field" />


              <label className="input-label" htmlFor="nom">Prénom et Nom</label>
              <input className={`text-input ${this.props.color}`}
                        required="required" type="text" name="nom" id="electre-app-feedback-name-input"/>
                
              <label className="input-label" htmlFor="email">Adresse e-mail</label>
              <input className={`text-input ${this.props.color}`} required="required" type="email" name="email" id="electre-app-feedback-mail-input"/>

              <label className="input-label" htmlFor="message">Message</label>
              <textarea className={`text-input textarea ${this.props.color}`} type="text" required="required" name="message" id="electre-app-feedback-name-input">
              </textarea>
              
              <div class="full-width mgt-m mgb-s flex-row justc-space-b alitems-start wrap
                          l-flex-column l-alitems-center s-mgt-s recaptcha-wrapper">
                <div className=" mgb-s">
                  <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_KEY} />
                </div>
                <input className="cta fill blue" type="submit" name="envoyer" value="Envoyer" />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const AppFeedbackPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Feedback sur l'application Electre.com</title>
      </Helmet>
      <AppFeedbackTemplate color="dark-blue"/>
    </Layout>
  )
}

export default AppFeedbackPage;
